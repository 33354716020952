import Hero from "../components/hero";
import ZoomIn from "../components/zoom-in";
import Blobs from "../components/blobs";
import Lightbox from "../components/lightbox";

class Default {
  beforeEnter(data) {
    this.initBlobs(data.next.container);
    // init hero
    const $hero = $(data.next.container).find('[data-hero="el"]');

    if ($hero.length) {
      new Hero($hero);
    }

    // init lightbox
    this.initLightboxes(data.next.container);
  }

  afterEnter(data) {
    window._scrollTrigger.refresh(true);
    const nextContainer = data.next.container;
    this.initZoomIn(nextContainer);
    this.runGA();
    const id = $(nextContainer).data("id");
    window._header.handleActiveClass(id);
    window._header.closeOverlay();
  }

  beforeLeave(data) {
    // jquery window scroll to top smooth
    $("html, body").animate({ scrollTop: 0 }, 500);
  }

  afterLeave(data) {
    Blobs.destroy();
    if (window._scrollTrigger) {
      const allTrigger = window._scrollTrigger.getAll();
      for (let i = 0; i < allTrigger.length; i++) {
        allTrigger[i].kill(true);
      }
    }
  }

  runGA = () => {
    if (typeof gtag === "function") {
      const path = window.location.href
        .replace(window.location.origin, "")
        .toLowerCase();
      gtag("js", new Date());
      gtag("config", "G-4ZF0FXJ1VY", {
        page_title: document.title,
        page_path: path,
      });
    }
  };

  initZoomIn = (scope) => {
    const $zoomIn = $(scope).find("[data-zoom-in]");

    if ($zoomIn.length) {
      $zoomIn.each((i, el) => {
        new ZoomIn($(el));
      });
    }
  };

  initBlobs = (scope) => {
    Blobs.init(scope);
  };

  initLightboxes = (scope) => {
    const $lightbox = $(scope).find("[data-toggle=modal]");

    if ($lightbox.length) {
      $lightbox.each((i, el) => {
        new Lightbox($(el), $(scope));
      });
    }
  };
}

export default Default;
