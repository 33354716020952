class ZoomIn {
  constructor($el) {
    this.$el = $el;
    this.$img = this.$el.find("img");

    this.init();
  }

  init = () => {
    this.animation = window._gsap.to(this.$img, {
      scale: 1,
      scrollTrigger: {
        trigger: this.$el,
        start: "center bottom",
        scrub: true,
        // markers: true,
        duaration: 1,
      },
    });
  };
}

export default ZoomIn;
