class Hero {
  constructor($el) {
    this.$el = $el;
    this.$jumplink = this.$el.find('[data-hero="jumplink"]');
    this.$jumplink.on("click", this.clickHandler);
    this.$target = $(this.$jumplink.attr("href"));
  }

  clickHandler = (e) => {
    e.preventDefault();
    this.jumpToContent();
  };

  jumpToContent = () => {
    if (this.$target.length) {
      $("html, body").animate(
        {
          scrollTop: this.$target.offset().top,
        },
        500
      );
    }
  };
}

export default Hero;
