import Default from './default';

class Standard extends Default {
	namespace = 'standard';

	beforeEnter = data => {
		super.beforeEnter(data);
	};

	beforeLeave = data => {
		super.beforeLeave(data);
	};

	afterLeave = data => {
		super.afterLeave(data);
	};

	afterEnter = data => {
		super.afterEnter(data);
	};
}

export default new Standard();
