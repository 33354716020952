import "../css/app.scss";
import barba from "@barba/core";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
// import DefaultTransition from "./transitions/default";

/** Pages */
import Standard from "./pages/standard";

/** Global Components */
import Header from "./components/header";

$(() => {
  window._$html = $("html");

  window._gsap = gsap;
  window._scrollTrigger = ScrollTrigger;
  window._gsap.registerPlugin(ScrollTrigger);

  // init global components
  window._header = new Header($('[data-header="el"]'));

  if ("scrollRestoration" in window.history) {
    window.history.scrollRestoration = "manual";
  }

  // init barba
  barba.init({
    timeout: 30000,
    // transitions: [DefaultTransition],
    views: [Standard],
    prevent: ({ el }) => {
      return (
        el.href.startsWith("#") ||
        el.href === window.location.href ||
        (el.id && el.id.startsWith("m"))
      );
    },
  });

  window._$html.addClass("initted");
});
