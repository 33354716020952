import Modal from "bootstrap/js/dist/modal";

class Lightbox {
  constructor($el, $scope) {
    console.log("Lightbox constructor");
    this.$scope = $scope;
    this.$el = $el;
    this.$target = $scope.find(this.$el.data("target"));
    this.$closeButton = this.$target.find('[data-lightbox="close"]');
    this.modal =
      Modal.getInstance(this.$target[0]) ?? new Modal(this.$target[0]);

    console.log("this.modal", this.modal);
    this.importScript();

    this.init();
  }

  init = () => {
    this.$el.on("click", this.handleClick);
    this.$closeButton.on("click", this.close);
  };

  handleClick = (e) => {
    e.preventDefault();
    this.modal.show();
  };

  importScript = () => {
    const script = document.createElement("script");
    script.src = "https://oa.zawin.ch/js/iframe.seamless.min.js";
    this.$scope.append(script);
  };

  close = () => {
    // console.log("this.modal", this.modal);
    this.$target.modal("hide");
  };
}

export default Lightbox;
