import Hamburger from "./hamburger";

class Header {
  stateClasses = {
    navigationLink: {
      active: "navigation__link--active",
    },
  };
  constructor($el) {
    this.$el = $el;
    this.$hamburger = this.$el.find('[data-hamburger="el"]');
    this.$links = this.$el.find('[data-navigation="link"]');
    this.hamburger = new Hamburger(this.$hamburger);
  }

  handleActiveClass = (id) => {
    this.$links.removeClass(this.stateClasses.navigationLink.active);

    // filter links by id
    const $links = this.$links.filter((i, el) => {
      return $(el).data("id") === id;
    });

    // add active class to links
    $links.addClass(this.stateClasses.navigationLink.active);
  };

  closeOverlay = () => {
    this.hamburger.close();
  };
}

export default Header;
