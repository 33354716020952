import Lottie from "lottie-web";

class Blobs {
  $el = null;
  lottieAnimations = null;
  observer = null;
  data = {
    hasAnimation: false,
  };
  selectors = {
    el: '[data-blob="el"]',
  };
  stateClasses = {
    loaded: "lottie--loaded",
  };

  constructor() {}

  init = (next) => {
    this.$el = $(next).find(this.selectors.el);
    this.lottieAnimations = {};
    this.$animations = [];
    this.data.hasAnimation = this.$el.length > 0;
    if (this.data.hasAnimation) {
      this.initLottie();
    }
  };

  initLottie = () => {
    this.$el.each((index, el) => {
      this.lottieAnimations[el.id] = Lottie.loadAnimation({
        container: el, // the dom element that will contain the animation
        renderer: "svg",
        loop: true,
        autoplay: false,
        path: el.dataset.src, // the path to the animation json
        rendererSettings: {
          className: "lottie__svg",
        },
      });

      this.lottieAnimations[el.id].addEventListener("DOMLoaded", () => {
        $(el).addClass(this.stateClasses.loaded);
      });
    });

    this.initObserver();
  };

  initObserver = () => {
    const _this = this;
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          // start animation
          _this.lottieAnimations[`${entry.target.id}`].play();
          // _this.lottieAnimations[i].play();
          // console.log('play', entry.target.id);
        } else {
          // stop animation
          _this.lottieAnimations[`${entry.target.id}`].pause();
          // console.log('pause', entry.target.id);
        }
      });
    });

    this.$el.each((i, el) => {
      this.observer.observe(el);
    });
  };

  destroy = () => {
    const keys = Object.keys(this.lottieAnimations);
    // destroy lottie Animations
    for (const key of keys) {
      this.lottieAnimations[key].destroy();
    }
    // disconnect observer
    if (this.observer) {
      this.observer.disconnect();
    }
  };
}

export default new Blobs();
