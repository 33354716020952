class Hamburger {
  stateClasses = {
    open: "navigation--open",
  };
  isOpen = false;
  constructor($el) {
    this.$el = $el;
    this.$el.on("click", this.clickHandler);
  }

  clickHandler = () => {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  };

  open = () => {
    window._$html.addClass(this.stateClasses.open);
    this.isOpen = true;
  };

  close = () => {
    window._$html.removeClass(this.stateClasses.open);
    this.isOpen = false;
  };
}

export default Hamburger;
